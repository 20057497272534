<template>
  <div class="login_container">
    <div class="login_box">
      <!-- logo区域 -->
      <div class="avatar_box">
        <img :src="app.src_logo" alt="logo" @click="$router.push('/')"/>
      </div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0"
        class="login_form"
      >
        <div class="txt">{{app.title}}</div>
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="请输入用户名"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <!-- 按钮区 -->
        <el-form-item class="btns">
          <el-button type="primary" round @click="login">登录</el-button>
          <el-button plain round @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 登录数据
      loginForm: {
        username: "",
        password: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      app: {app_name:'', title:'', src_logo:require('../assets/img/daphnia.jpg')},
      logoList:[
        {
          title: 'Plankton Database 2022 v1.0',
          app_name: 'plankton',
          src_logo: require('../assets/img/logo_150.jpg'),
        },
      ],
      // 表单验证
      loginFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    // let queryStr = this.$route.query.app || localStorage.getItem('app') || 'zj_sport'
    let queryStr = 'plankton';
    console.log('?app=', queryStr)
    this.app = this.logoList.find(item=>item.app_name===queryStr)
    localStorage.setItem('app', this.app.app_name)
  },

  methods: {
    // 获取登录信息    
    async getAuthorization(username, password) {
      let res = await this.$api.plankton.Login({username, password});
      if(res.success){
        this.$message.success("Login success.");
        // 登录成功，写入本地数据
        localStorage.setItem('app', this.app.app_name)
        localStorage.setItem('token', res.token)
        // 根据不同的应用，转入不同的页面
        this.$router.push("/PlanktonDB")
      }
      else{
          this.$message.error("Login failed.");
          // 清除本地数据
          // localStorage.removeItem('app')
          localStorage.removeItem('token')
      }
    },
    // 重置表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
      // 清除本地数据
      // localStorage.removeItem('app')
      localStorage.removeItem('token')
    },
    
    // 登录按钮
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) {
          this.$message.error("Login failed.");
          return;
        } else {
          this.getAuthorization(this.loginForm.username, this.loginForm.password);
        }
        // if (res.meta.status !== 200) 
          // return this.$message.error("Login failed");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 330px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 150px;
    width: 150px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  .txt {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.btns {
  display: flex;
  justify-content: center;
  .el-button {
    width: 100px;
  }
}

@media only screen and (max-width: 550) {
  .login_box {
    width: 300px;
  }
}
</style>
